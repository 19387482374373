import React, {FC} from "react"
import styled from "styled-components"
import GatsbyImage, { FluidObject } from "gatsby-image"
interface IPressItemProps {
  rawContent: boolean
  className?:string
  imageFluid?: FluidObject
  title?: string
  name?: string
  fileUrl?: string
  content?: string
}

const PressItem: FC<IPressItemProps> = ({ className, imageFluid, title, name, fileUrl, content, rawContent }) => {
  return (
    <div className={className}>
      <div className="press__member-pic">
        <GatsbyImage fluid={imageFluid as FluidObject} alt="" />
      </div>
      <div className="press__member-info">
        <span className="title">{title}</span>
        <span className="name">{name}</span>
        { rawContent ? <p>{content}</p> : <div dangerouslySetInnerHTML={{__html: content || ""}} /> }
        <a href={fileUrl} target="_blank" className="btn forshowcase">Read More</a>
      </div>
    </div>
  )
}

export default styled(PressItem)`
  position: relative;
  width:100%;
  margin-top:50px;
  background-color:#f1f1f1;
  &:first-child {
    margin-top:30px;
  }
  @media screen and (min-width:900px) {
    flex-shrink:0;
    width:49%;
    margin-right:2%;
    margin-top:0;
    margin-bottom:30px;
    &:first-child {
      margin-top:0;
    }
    &:last-child {
      margin-right:0;
    }
    &:nth-child(2n+2){
      margin-right:0;
    }
  }
  .press__member-pic {
    padding:15px;
    background: #E5E5E5;
    img {
      display:block;
    }
  }
  .press__member-info {
    // position: relative;
    span {
      display:block;
      &.title {
        font-family: 'Bankgothic Regular',sans-serif;
        font-size:25px;
        color:red;
        font-weight:bold;
        text-transform: uppercase;
        margin-bottom:10px;
      }
      &.name {
        font-family: 'BankGothicLtBTLight',sans-serif;
        font-size:27px;
      }
    }
    padding:35px 10px;
    background: #f1f1f1;
    p {
      //font-weight: bold;
      line-height:25px;
    }
  }
  .press__logo {
    position: absolute;
    bottom:100%;
    left:50%;
    transform: translate(-50%, 50%);
    width:40%;
    padding:8px 0;
    background:#f1f1f1;
    z-index:100;
  }
`
