import React from "react"
import styled from "styled-components"

interface IPressListProps {
  className?:string
  children?: React.ReactNode
}

const PressList: React.FC<IPressListProps> = ({ className, children }) =>{
  return (
    <div className={className}>
      {children}
    </div>
  )
}

export default styled(PressList)`
  display:flex;
  flex-wrap:wrap;
`
