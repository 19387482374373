import React from "react"
import Layout from "../components/layout"
import { graphql, Link, PageProps } from "gatsby"
import SEO from "../components/seo"
import styled from "styled-components"
import { PageTitle, SpanDivider } from "../components/UtilComponents"
import { FluidObject } from "gatsby-image"
import PressList from "../components/PressList"
import PressItem from "../components/PressItem"
// @ts-ignore
import Fade from "react-reveal/Fade"
// @ts-ignore
import { getSectionsData} from "../util/helpers"

type SeoData = {
  iso: string
  metaTitle: string
  metaDescription: string
  metaKeywords: string
} | undefined

type TemplateData = {
  locales: SeoData[]
}

type PressListData = {
  id: number
  title: string
  name: string
  content: string
  fileUrl: string
  imageFluid: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

type LocalPressData = {
  iso: string
  metaTitle: string
  metaDescription: string
  title: string
  content: string
  description?: string
} | undefined

type LocalAdditionalData = {
  iso: string
  value: string
} | undefined

type PressData = {
  uid: string
  additionalData: {
    key: string
    locales: LocalAdditionalData[] | undefined
  }[] | undefined
  locales: LocalPressData[] | undefined
  imageFile: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  hardCopyFile: {
    publicURL: string
  }
}

interface IPressProps extends PageProps<{
  allPressJson: {
    nodes: PressListData[]
  },
  allPress: {
    nodes: PressData[]
  }
  template: TemplateData
}> {
  className?: string
}

const Press: React.FC<IPressProps> = ({ className,location:{pathname:path}, data, pageContext }) => {

  // const presses:Array<PressListData> = data.allPressJson.nodes
  const {lang} = pageContext
  const secData = getSectionsData(data.template.sections)
  const presses: Array<PressData> = data.allPress.nodes
  const metaSeoText:SeoData = data.template.locales.find(l => l.iso === lang)
  // @ts-ignore
  return (
    <Layout lang={lang} page={path}>
      <SEO title={metaSeoText?.metaTitle || ""}
           description={metaSeoText?.metaDescription || ""}
           keywords={metaSeoText?.metaKeywords || ""}
      />
      <div className={className}>
        <div className="content content--about content--inner">
          <Fade top>
            <PageTitle>
              <SpanDivider fontSize="calc(30px + (40 - 30) * ((100vw - 300px) / (900 - 300)))"> | </SpanDivider> Press</PageTitle>
          </Fade>
          <section className="section press press-page">
            {secData.head.description[pageContext.lang] && <p className="subheader-text">{secData.head.description[pageContext.lang]}</p>}
            <PressList>
              {presses.map(press => {
                const localPressData:LocalPressData = press.locales?.find(l => l?.iso === lang)
                const localAdditionalData: LocalAdditionalData = press.additionalData ? press.additionalData[0].locales?.find(l => l?.iso === lang) : ""
                return <PressItem
                  key={press.uid + "_" + localPressData?.title}
                  title={localPressData?.title}
                  name={localAdditionalData?.value}
                  content={localPressData?.content}
                  fileUrl={press.hardCopyFile.publicURL}
                  imageFluid={press.imageFile.childImageSharp.fluid}
                />
              })}
            </PressList>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
query PressPageTemplate($templateName: String!) {
  template(name: {eq: $templateName}) {
    sections {
        items {
          ... on DataItemTextGet {
            __typename
            locales {
              iso
              value
            }
          }
          key
        }
        keyPrefix
      }
    locales {
      iso
      metaDescription
      metaKeywords
      metaTitle
    }
  }
   allPress {
      nodes {
        uid
        locales {
          iso
          metaTitle
          metaDescription
          title
          value
          description
          content
        }
       additionalData {
          key
          locales {
            iso
            value
          }
        }
        hardCopyFile {
          publicURL
        }
        imageFile {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
export default styled(Press)`
  max-width:1200px;
  margin:0 auto;
  padding-top:0;
  padding-bottom:60px;
  @media screen and (min-width:900px) {
    padding-top:30px;
  }
`
